var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 white create-ship"},[_c('h1',{staticClass:"my-8 text-center"},[_vm._v(_vm._s(_vm.isEditMode ? _vm.$t('SHIP_ARRIVAL.EDIT.TITLE') : _vm.$t('SHIP_ARRIVAL.CREATE.TITLE')))]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"close-icon-element",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('closeDialog', true)}}},[_c('v-icon',{attrs:{"color":hover ? 'close-icon-darken1' : 'close-icon'}},[_vm._v(" fas fa-times ")])],1)]}}])}),_c('div',{staticClass:"content-box pa-6 pt-0 d-flex"},[_c('div',{staticClass:"flex-grow-1 pr-12 scroll-container"},[_c('transition',{attrs:{"name":"step-fade","mode":"out-in"}},[_c(_vm.currentStepView,{tag:"component",attrs:{"is-loading":_vm.isLoading},on:{"mounted":function($event){return _vm.onStepMounted($event)},"is-invalid-change":function($event){return _vm.onIsInvalidChange($event)},"next-step":function($event){return _vm.onNextStep()},"previous-step":function($event){return _vm.onPreviousStep()},"finish":function($event){return _vm.onFinish()}}})],1)],1),_c('div',{staticClass:"steps-container d-flex flex-column"},[_c('transition-group',{attrs:{"name":"steps-list"}},_vm._l((_vm.steps),function(step,index){return _c('div',{key:step.title,staticClass:"step d-flex"},[_c('div',{staticClass:"icon-container"},[_c('div',{staticClass:"steps-connection divider",class:{
                              first: index === 0,
                              last: index === _vm.steps.length -1,
                          }}),_c('div',{staticClass:"icon-background white",class:{
                                      done: step.done,
                                      border: !step.done,
                                      'selected-border' : index === _vm.currentStepIndex,
                                      'has-error': index === _vm.currentStepIndex && _vm.isOneStepInvalid
                                  }}),_c('v-avatar',[((step.done && !_vm.isEditMode) || (_vm.isEditMode && index === _vm.currentStepIndex))?_c('v-icon',{attrs:{"color":index === _vm.currentStepIndex && _vm.isOneStepInvalid ? 'error' : 'primary'}},[_vm._v(" "+_vm._s(_vm.isEditMode ? 'fas fa-info-circle' : 'fas fa-check-circle')+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"step-information d-flex flex-column",class:{
                              'error--text': index === _vm.currentStepIndex && _vm.isOneStepInvalid,
                              'has-error': index === _vm.currentStepIndex && _vm.isOneStepInvalid,
                              'not-allowed': _vm.isOneStepInvalid,
                              'is-edit-mode': _vm.isEditMode
                              },on:{"click":function($event){return _vm.onStepClick(index)}}},[_c('h4',{staticClass:"step-title",class:{ 'accent--text': index === _vm.currentStepIndex && !_vm.isOneStepInvalid }},[_vm._v(" "+_vm._s(step.title)+" ")]),_c('span',{staticClass:"step-description secondary-text--text body-3 font-weight-medium mb-6"},[_vm._v(" "+_vm._s(step.description)+" ")])])])}),0)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }