







































































import {Component, Vue} from 'vue-property-decorator';
import {shipArrivalStoreActions, shipArrivalStoreGetter, shipArrivalStoreMutations} from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import {namespace} from 'vuex-class';
import StepBaseComponent from '@/components/shipArrival/create/steps/StepBaseComponent.vue';
import Voyage from '@/models/Voyage.model';
import {voyageStoreActions} from '@/store/voyage.store';

type ShipArrivalCreateSteps = { title: string, description: string, done: boolean, component: string };

const ShipArrivalStore = namespace('shipArrival');
const VoyageStore = namespace('voyage');

@Component({
  components: {
    Step1Component: () => import(
        /* webpackChunkName: "Step1Component" */
        '@/components/shipArrival/create/steps/Step1.component.vue'),
    Step2Component: () => import(
        /* webpackChunkName: "Step2Component" */
        '@/components/shipArrival/create/steps/Step2.component.vue'),
    Step3Component: () => import(
        /* webpackChunkName: "Step3Component" */
        '@/components/shipArrival/create/steps/Step3.component.vue'),
  }
})
export default class CreateShipArrivalComponent extends Vue {
  public steps: ShipArrivalCreateSteps[] = [];
  public currentStepIndex: number = 0;
  public isLoading: boolean = false;
  /**
   * Determines if one step is invalid
   */
  public isOneStepInvalid: boolean = false;

  private LIST_ANIMATION_DELAY: number = 80;

  @ShipArrivalStore.Getter(shipArrivalStoreGetter.CURRENT_ARRIVAL)
  public _currentShipArrival!: ShipArrival;

  public get currentShipArrival(): ShipArrival {
    return this._currentShipArrival;
  }

  @ShipArrivalStore.Action(shipArrivalStoreActions.CREATE)
  public createShipArrivalAction!: (payload: { shipArrival: ShipArrival }) => Promise<ShipArrival>;

  @ShipArrivalStore.Action(shipArrivalStoreActions.UPDATE)
  public updateShipArrivalAction!: (payload: { shipArrival: Partial<ShipArrival> }) => Promise<ShipArrival>;

  @ShipArrivalStore.Mutation(shipArrivalStoreMutations.SET_VOYAGE_FOR_CURRENT_SHIP_ARRIVAL)
  public setVoyageForCurrentShipArrival!: (value: Voyage) => void;

  @VoyageStore.Action(voyageStoreActions.CREATE)
  public createVoyageAction!: (payload: Voyage) => Promise<Voyage>;

  @VoyageStore.Action(voyageStoreActions.UPDATE)
  public updateVoyageAction!: (payload: Partial<Voyage>) => Promise<Voyage>;

  get isEditMode(): boolean {
    return !!this.currentShipArrival.id;
  }

  get currentStepView(): string {
    if (this.steps[this.currentStepIndex]?.component) {
      return this.steps[this.currentStepIndex].component;
    } else {
      return 'Step1Component';
    }
  }

  /**
   * Reference to current active step
   */
  public currentStepComponent: StepBaseComponent | null = null;

  public async created() {
    // init steps
    const tmpSteps = [
      {
        title: this.$t('SHIP_ARRIVAL.CREATE.STEPS.1.TITLE').toString(),
        description: this.$t('SHIP_ARRIVAL.CREATE.STEPS.1.SUBTITLE').toString(),
        done: false,
        component: 'Step1Component'
      },
      {
        title: this.$t('SHIP_ARRIVAL.CREATE.STEPS.2.TITLE').toString(),
        description: this.$t('SHIP_ARRIVAL.CREATE.STEPS.2.SUBTITLE').toString(),
        done: false,
        component: 'Step2Component'
      },
      {
        title: this.$t('SHIP_ARRIVAL.CREATE.STEPS.3.TITLE').toString(),
        description: this.$t('SHIP_ARRIVAL.CREATE.STEPS.3.SUBTITLE').toString(),
        done: false,
        component: 'Step3Component'
      }
    ];

    // trigger steps-list animation
    for (const step of tmpSteps) {
      await new Promise<void>((resolve) => setTimeout(() => {
        this.steps.push(step);
        resolve();
      }, this.LIST_ANIMATION_DELAY));
    }
  }

  public onStepMounted(currentStep: StepBaseComponent) {
    this.currentStepComponent = currentStep;
  }

  public onIsInvalidChange(isInValid: boolean) {
    this.isOneStepInvalid = isInValid;
  }

  /**
   * Event handler to handle direct click on a step. This is only in edit mode possible
   * @param index
   */
  public onStepClick(index: number) {
    if (this.isEditMode) {
      // Check if current step is valid
      this.isOneStepInvalid = !this.currentStepComponent!.validate();
      if (!this.isOneStepInvalid) {
        this.currentStepIndex = index;
      }
    }
  }

  /**
   * Event handler to handle next step intention
   */
  public onNextStep() {
    this.steps[this.currentStepIndex].done = true;

    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
    }
  }

  /**
   * Event handler to handle previous step intention
   */
  public onPreviousStep() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
    }
  }

  private async onFinish() {
    try {
      this.isLoading = true;
      if (this.isEditMode) {
        // Handle edition of ship arrival
        // -- but first -> update voyage
        const updatedVoyage = await this.updateVoyageAction(this.currentShipArrival.voyage as Voyage);
        // after that -> update ship arrival
        this.currentShipArrival.voyage = updatedVoyage;
        this.setVoyageForCurrentShipArrival(updatedVoyage);
        await this.updateShipArrivalAction({shipArrival: this.currentShipArrival});
      } else {
        // Handle creation of ship arrival
        // -- but first -> create voyage
        const createdVoyage = await this.createVoyageAction(this.currentShipArrival.voyage as Voyage);
        // after that -> create ship arrival
        this.setVoyageForCurrentShipArrival(createdVoyage);
        await this.createShipArrivalAction({shipArrival: this.currentShipArrival});
      }
      this.$emit('closeDialog', true)
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      this.isLoading = false;
    }
  }
}
